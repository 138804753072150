:root {
  --container-bg-color: #333;
  --left-bg-color: black;
  --left-button-hover-color: white;
  --right-bg-color: white;
  --right-button-hover-color: black;
  --hover-width: 75%;
  --other-width: 25%;
  --speed: 1000ms;
}

// https://codepen.io/bradtraversy/pen/dJzzdB

.landing-page__wrapper {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 30px;
    }
  }

  button {
    cursor: pointer;
  }

  button:hover {
    scale: 1.1;
    transition: 200ms ease-in-out;
  }



  .black-side__wrapper {
    width: 50%;
    height: 100%;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 900px) {
      width: 100%;
    }



    .title {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 100px;
    }

    .black-content__container {
      width: 500px;
      height: 193px;
      transform: translateY(-70px);

      @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 300px;
        height: 193px;
        margin-top: 160px;
        margin-left: 70px;
      }

      h1 {
        font-size: 50px;
        line-height: 1;

        @media screen and (max-width: 900px) {
          font-size: 30px;
          text-align: left;
        }
      }

      button {
        width: 185px;
        height: 76px;
        border-radius: 19px;
        border: none;
        font-size: 24px;
        background-color: white;
        font-weight: 700;
        font-family: poppins;
        margin-top: 20px;


      }
    }
  }


  .white-side__wrapper {
    width: 50%;
    height: 100%;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    .white-content__container {
      height: 338px;
      width: 500px;
      margin-top: -190px;

      @media screen and (max-width: 900px) {
        margin-top: 40px;
      }

      h1 {
        font-size: 50px;
        line-height: 1;

        @media screen and (max-width: 900px) {
          font-size: 30px
        }
      }

      button {
        width: 185px;
        height: 76px;
        border-radius: 19px;
        border: none;
        font-size: 24px;
        background-color: black;
        color: white;
        font-weight: 700;
        font-family: poppins;
        margin-top: 20px;
      }
    }
  }
}

* {
  margin: 0;
  scroll-behavior: smooth;
}


body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}



h4 {
  font-size: 4rem;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.button {
  display: block;
  position: absolute;
  left: 50%;
  top: 40%;
  height: 2.5rem;
  padding-top: 1.3rem;
  width: 15rem;
  text-align: center;
  color: #fff;
  border: #fff solid 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transform: translateX(-50%);
}

.split.left .button:hover {
  background-color: var(--left-button-hover-color);
  border-color: var(--left-button-hover-color);
}

.split.right .button:hover {
  background-color: var(--right-button-hover-color);
  border-color: var(--right-button-hover-color);
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: var(--container-bg-color);
}

.split {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.split.left {
  left: 0;
  background: url('https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Black_colour.jpg/1536px-Black_colour.jpg') center center no-repeat;
  background-size: cover;
  color: white;
}

.split.left:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--left-bg-color);
}

.split.right {
  right: 0;
  color: black;
  background: url('https://wallpaperaccess.com/full/1586320.jpg') center center no-repeat;
  background-size: cover;
}

.split.right:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--right-bg-color);
}

.split.left,
.split.right,
.split.right:before,
.split.left:before {
  transition: var(--speed) all ease-in-out;
}

.hover-left .left {
  width: var(--hover-width);
}

.hover-left .right {
  width: var(--other-width);
}

.hover-left .right:before {
  z-index: 2;
}

.hover-right .right {
  width: var(--hover-width);
}

.hover-right .left {
  width: var(--other-width);
}

.hover-right .left:before {
  z-index: 2;
}

@media(max-width: 800px) {
  h1 {
    font-size: 2rem;
  }

  .button {
    width: 12rem;
  }
}

@media(max-height: 700px) {
  .button {
    top: 70%;
  }
}

.navbar__bg {
  color: white;
  background-color: black;
}

.navbar-inverted__bg {
  color: black;
  background-color: white;
}

.navbar__wrapper {
  width: 100vw;
  height: 50px;
  display: flex;
  position: absolute;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 10px;
  padding-left: 30px;
  z-index: 10;
  flex-direction: row;

  span {
    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: auto;
    }
  }

  h1 {
    font-size: 40px;
    cursor: pointer;
  }

  .menu__container {
    z-index: 1;
    margin: 20px 20px 0 0;
    color: black;
    font-size: 40px;
  }

  h1:hover {
    scale: 1.1;
    transition: 200ms ease-in-out;
  }

  span:hover {
    scale: 1.1;
    transition: 200ms ease-in-out;
  }
}

.right-content__wrapper {
  height: 100vh;
}


.project-component__wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;


  img {
    width: 600px;
    height: 600px;
    object-fit: cover;
    border-radius: 20px;
    margin: 10px;
  }

  .top-section__wrapper {
    width: 100vw;
    padding: 30px;
    margin: 10px;
    display: flex;
    justify-content: space-evenly;

    .text-button__container {
      display: flex;
      flex-direction: column;
      align-items: center;


      h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 160px;
        line-height: 96.5%;
        text-align: center;
      }

      button {
        height: 120px;
        width: 120px;
        border-radius: 80px;
        border: none;
        box-shadow: 0px 3px 9px -3px rgba(0, 0, 0, 0.25);
        background-color: black;
        margin-top: 40px;
      }
    }
  }


  .description__wrapper {
    width: 80%;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 100.69%;
      letter-spacing: -0.045em;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 115.69%;
      letter-spacing: -0.045em;
      margin-bottom: 50px;
    }

    h6 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 100.69%;
      letter-spacing: -0.045em;
      display: flex;
      justify-content: flex-end;
    }

  }


  .chosen-projects {
    justify-content: flex-start;
    width: 83.5%;
    margin-bottom: 20px;
    margin-top: 45px;


    @media screen and (max-width: 900px) {
      font-size: 25px;
      margin-left: 55px;
      margin-bottom: 30px;
    }
  }



  .left {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 900px) {
    .left {
      flex-direction: inherit;
    }
  }

  .project-right-component__wrapper {
    height: 400px;
    margin: 10px;
    margin-bottom: 40px;
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 30px;
    width: 82.5%;

    @media screen and (max-width: 900px) {
      margin-top: 150px
    }

    .content__container {

      padding: 20px;

      h1 {
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: -0.045em;
        margin: 0;
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.045em;
        white-space: "pre-wrap";
      }
    }
  }
}



@media screen and (max-width: 900px) {

  .project-right-component__wrapper {
    height: 600px;
    margin: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column-reverse;


    .content__container {
      margin: 0;

      h1 {
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: -0.045em;
        margin: 0;
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        width: 300px;
      }
    }
  }
}



.shop__wrapper {
  background-color: white;
  color: black;
  height: 100%;
  width: 100vw;
  margin: 0;
  display: flex;
  flex-direction: column;

  hr {
    width: 80%;
    display: flex;
    justify-content: center;
  }


  .title__container {

    h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 120px;
      line-height: 100.69%;
      text-align: center;
      letter-spacing: -0.045em;
      margin-bottom: 75px;

      @media screen and (max-width: 900px) {
        font-size: 80px;
      }
    }
  }

  .categories__wrapper {

    padding: 70px;

    @media screen and (max-width: 900px) {
      padding: 20px;

      h2 {
        font-size: 20px;
      }

    }


    h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 120px;
      line-height: 100.69%;
      text-align: center;
      letter-spacing: -0.045em;
      margin: 0;

      @media screen and (max-width: 900px) {
        font-size: 10px;
      }

    }

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 100.69%;
      text-align: center;
      letter-spacing: -0.045em;
      margin-bottom: 20px;

      @media screen and (max-width: 900px) {
        font-size: 30px;
      }
    }


    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 100.69%;
      text-align: center;
      letter-spacing: -0.045em;
      margin-bottom: 20px;
    }


    .category__container {
      display: flex;
      margin-top: 20px;
      padding: 20px;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
      }
    }

    img {
      width: auto;
      height: 200px;
      border-radius: 40px;
      object-fit: cover;

      @media screen and (max-width: 900px) {
        width: auto;
        height: 80px;
      }
    }

    .title-button__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      button {
        height: 70px;
        width: 120px;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        font-size: 20px;

        @media screen and (max-width: 900px) {
          height: 70px;
          width: 120px;
        }
      }
    }
  }
}


.product-category__wrapper {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .products__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .product-content__wrapper {
    display: flex;
    padding: 30px;
    height: 100px;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-bottom: 40px;
    box-shadow: 0px 1px 5px 0px #676767;
    border-radius: 10px;
    padding: 10px 80px;

    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      padding: 20px;
    }


    .product-content__container {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 20px;
      margin-right: 40px;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 900px) {
        width: 100%;
        margin-right: 0;
      }


      h2 {
        font-size: 20px;
        font-weight: 800;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
      }

    }

    .product-image__container {
      width: auto;

      img {
        width: 300px;
        height: 300px;
        object-fit: cover;
        border-radius: 40px;

        @media screen and (max-width: 900px) {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

}


.mixers__wrapper {
  width: 100vw;
  height: 100vh;
}

.duk-page__wrapper {
  display: flex;
  justify-content: space-evenly;
  height: 60vh;

  .duk-content__wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;

    .desc__container {
      display: flex;
      flex-direction: column;

      p {
        line-height: 18px;

        @media screen and (max-width: 900px) {
          font-size: 100px
        }
      }

      button {
        width: 120px;
      }
    }
  }

  .image__container {
    display: flex;
    justify-content: center;
    align-items: center;


    img {
      width: 400px;
      height: 400px;
      object-fit: cover;
      border-radius: 40px;
    }
  }
}

@media screen and (max-width: 900px) {
  .duk-page__wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin-top: 60px;

    .duk-content__wrapper {
      display: flex;
      width: 80%;

      h1 {
        font-size: 16px;
      }

      .desc__container {
        display: flex;
        flex-direction: column;

        p {
          line-height: 15px;
          font-size: 16px;
        }

        button {
          width: 120px;
        }
      }
    }

    .image__container {
      display: flex;


      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 40px;
      }
    }
  }
}


.video__container {
  iframe {
    border-radius: 20px;
    border: none;
    width: 900px;
    height: 500px;
  }
}

@media screen and (max-width: 900px) {
  .video__container {
    iframe {
      border-radius: 20px;
      border: none;
      width: 350px;
      height: 250px;
    }
  }
}




.hamburger__container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 20px;
}




.duk-projects__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  .duk-project__container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 4px 8px 36px -13px rgba(0, 0, 0, 0.75);
    width: 80vw;

    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      justify-content: center;
    }

    .duk-content__container {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        height: 40px;
      }

      @media screen and (max-width: 900px) {
        width: 80%;
        justify-content: center;
        align-items: center;
      }
    }


    .duk-image__container {
      object-fit: cover;

      img {
        width: 300px;
        height: auto;
        object-fit: cover;
        border-radius: 10px;

        @media screen and (max-width: 900px) {
          width: 200px;
          height: auto;
          margin-top: 20px;
        }
      }
    }

  }

  .client-images {
    display: flex;
    flex-wrap: wrap;

    .client-image__container {
      margin: 10px;
    }
  }
}